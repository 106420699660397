import React from 'react';
import { Link } from 'react-router-dom';
import OrderStatus from 'Components/OrderStatus';
import AcceptAvailableOrder from 'Components/AcceptAvailableOrder';
import ConfirmOffer from 'Components/ConfirmOffer';
import OrderCommentPopout from 'Components/OrderArchives/OrderCommentPopout';
import MemorialPageWarning from 'Components/OrderArchives/MemorialPageWarning';
import ArchiveButton from 'Components/ArchiveButton';
import FavoriteButton from 'Components/FavoriteButton';
import RoundFlag from 'Components/RoundFlag';
import { formatDate, getOrderPersonOfReference } from './functions';
import { If } from 'Conditionals';
import { formatDateForCalendar } from './DateHelpers';
import RestoreButton from 'Components/RestoreButton';
import { getResourceUrl } from 'Library/Orders';
import { getOrderFuneralPlace } from 'Library/OrderHelperFuntions';
import AvailableOrderWarning from 'Components/OrderArchives/AvailableOrderWarning';
import { useTranslation } from 'react-i18next';
import SingleTag from 'Components/OrderTags/SingleTag';
import PaidInvoiceStatus from 'Components/PaidInvoiceStatus';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import AppointmentDateTime from 'Components/PaymentComponents/AppointmentDateTime';

export function renderOrderLinkColumn ({
  order,
  available,
  isAdmin,
  order: {
    id,
    custom_info: customInfo
  }
}) {
  const link = getLink({ available, isAdmin, order });
  const name = getOrderPersonOfReference(order);

  return (
    <div className='order-table__deceased'>
      {link
        ? (
          <Link to={link}>{name}</Link>
          )
        : name}
      <OrderCommentPopout
        id={id}
        header={name}
        text={customInfo ?? ''}
        link={link}
      />
    </div>
  );
}

export function renderFavoriteColumn ({ order }) {
  return (<FavoriteButton order={order} />);
}

export function renderMarketColumn ({ order }) {
  return (<RoundFlag order={order} />);
}

export function renderOrderNumberColumn ({
  order,
  available,
  isAdmin,
  order: {
    class: orderClass,
    created_at: createdAt,
    number,
    id,
    is_test: isTest,
    secret = false,
    tags = []
  }
}) {
  const { t } = useTranslation();
  const isOrder = orderClass === 'App\\Order';
  const showMemorialPageWarning = !order.hasMemorialPage;
  const link = getLink({ available, isAdmin, order });
  const memorialLink = `${link}/minnessida`;
  const secretIcon = <span className='column-info secret-suffix-table' title={t('Secrecy')}>S</span>;
  const testIcon = <span className='column-info test-suffix-table'> ({t('test')})</span>;
  const { loggedInUserCountry: country } = useLoggedInUser();

  return (
    <div className='order-number-column'>
      <div className='order-number-column__info'>
        <div className='order-number-column__info-inner'>
          <span className='order-number-column__number'>#{number}</span>
          <If condition={isOrder && showMemorialPageWarning}>
            <MemorialPageWarning id={id} link={memorialLink} />
          </If>
          <If condition={!!order.available_at}>
            <AvailableOrderWarning id={id} />
          </If>
          {secret ? secretIcon : null}
          {isTest ? testIcon : null}
        </div>
        <span className='column-info order-number-column__date'>{formatDate(createdAt, country.code)}</span>
        {order.tags && (
          <ol className='order-tags__list'>
            {order.tags && order.tags.map((tag, index) => (
              <SingleTag key={index} tag={tag} />
            ))}
          </ol>
        )}
      </div>
      <a className='btn order-number-column__btn' href={link}>{t('Show order')}</a>
    </div>
  );
}

export function renderNextMeetingColumn ({ order, available, isAdmin, order: { appointments, customer } }) {
  const { t } = useTranslation();
  const nextMeetingArray = appointments.filter((appointment) => appointment.type === 'customer_meeting' && new Date(appointment.start_date) > new Date()).sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

  if (nextMeetingArray.length === 0) {
    const link = getLink({ available, isAdmin, order });

    if (!link) {
      return null;
    }

    return (
      <Link to={`${link}/sessions`} className='btn meeting'>{t('Book meeting')}</Link>
    );
  }

  const appointment = nextMeetingArray[0];

  if (!appointment.start_date || !appointment.end_date) {
    return null;
  }

  return <AppointmentDateTime start={appointment.start_date} meetingLength={appointment.meeting_length} customerTimezone={customer?.timezone} />;
}

export function renderCustomerMeetingColumn ({ appointments }) {
  const { t } = useTranslation();
  const appointment = appointments.find(({ type }) => type === 'customer_meeting');

  return (appointment && appointment.start_date && appointment.end_date)
    ? formatDateForCalendar(appointment.start_date, appointment.end_date)
    : t('No customer meeting booked');
}

export const renderStatusColumn = ({
  order,
  percentageComplete = null
}) => (
  <div>
    <OrderStatus
      order={order}
      percentageComplete={percentageComplete}
    />

    <PaidInvoiceStatus
      order={order}
    />
  </div>
);

export function renderActionColumn (props) {
  const { t } = useTranslation();
  const {
    order,
    isAdmin = false,
    available = false
  } = props;

  const getUserName = () => {
    if (!order.user || !order.user.name) {
      return `(${t('Not selected)')})`;
    }

    return order.user.name;
  };

  if (available && !isAdmin) {
    return (
      <AcceptAvailableOrder {...props} />
    );
  }

  return (
    <div className='action-col'>
      <div className='action-col__admin'>
        <h5>{t('Advisor')}</h5>
        <span className='column-info'>{getUserName()}</span>
      </div>

      <If condition={order.status === 'offer'}>
        <ConfirmOffer {...props} />
      </If>

      <If condition={['invoiced', 'partially_invoiced', 'passed', 'paid_inquiry'].includes(order.status)}>
        <ArchiveButton order={order} />
      </If>

      <If condition={order.status === 'trashed'}>
        <RestoreButton order={order} />
      </If>
    </div>
  );
}

export const renderLocationColumn = (order, isFuneralOrder) => {
  const { loggedInUserCountry: country } = useLoggedInUser();
  const location = (order.location) ? order.location.name : '-- ';

  function renderFuneralInfo () {
    if (!isFuneralOrder) {
      return null;
    }

    const ceremony = order.appointments.find(({ type }) => type === 'ceremony');
    const ceremonyStartDate = (ceremony) ? formatDate(ceremony.start_date, country.code) : null;
    const funeralPlace = getOrderFuneralPlace(order);

    if (!funeralPlace && !ceremonyStartDate) {
      return null;
    }

    if (funeralPlace) {
      return (ceremonyStartDate) ? `${funeralPlace}, ${ceremonyStartDate}` : funeralPlace;
    }

    return null;
  }

  return (
    <div>
      <span className='column-info location'>
        <strong>{location}</strong>
      </span>
      <If condition={renderFuneralInfo()}>
        <span className='column-info'>
          {renderFuneralInfo()}
        </span>
      </If>
    </div>
  );
};

export const renderOrderCaseTypeColumn = ({
  case_type: caseType
}) => (
  <span>{caseType || '--'}</span>
);

function getLink ({ available = false, isAdmin = false, order }) {
  if (order.status === 'trashed' || (available && !isAdmin)) {
    return null;
  }

  return getResourceUrl(order);
}

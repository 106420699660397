import { useTranslation } from 'react-i18next';
import useTask from 'ReduxHooks/useTask';

export default function useOrderShortcodes ({ type = 'all' }) {
  const { t } = useTranslation();
  const { taskCountry } = useTask();

  function getAppointmentTypes () {
    const meetingTypes = {
      customer_meeting: t('Customer meeting')
    };

    if (type === 'order' || type === 'all') {
      meetingTypes.ceremony = t('Ceremony');
      meetingTypes.visning = t('Viewing');
      meetingTypes.memorial = t('Memorial service');
      meetingTypes.entombment = t('Entombment');
    }

    const appointmentTypes = {};

    for (const key in meetingTypes) {
      appointmentTypes[`${key}.datetime`] = `${meetingTypes[key]} ` + t('date & time');
      appointmentTypes[`${key}.start_date`] = `${meetingTypes[key]} ` + t('start date');
      appointmentTypes[`${key}.start_time`] = `${meetingTypes[key]} ` + t('start time');
      appointmentTypes[`${key}.end_date`] = `${meetingTypes[key]} ` + t('end date');
      appointmentTypes[`${key}.end_time`] = `${meetingTypes[key]} ` + t('end time');
      if (key === 'customer_meeting') {
        appointmentTypes[`${key}.place`] = `${meetingTypes[key]} ` + t('place');
      }

      if (key === 'customer_meeting' && type === 'therapy_order') {
        appointmentTypes[`${key}.verification_link`] = `${meetingTypes[key]} ` + t('Verification link');
        appointmentTypes[`${key}.meet_link`] = `${meetingTypes[key]} ` + t('Meet link');
        appointmentTypes[`${key}.all_meetings_list`] = `${meetingTypes[key]} ` + t('Meetings list');
      }
    }

    return appointmentTypes;
  }

  function getShortcodes () {
    const data = {
      order: {
        company_address: t('Företagets adress'),
        status_translated: t('Status'),
        location: t('Location'),
        number: t('Order number'),
        id: t('Order ID'),
        custom_info: t('Note'),
        user: t('Advisor/Lawyer')
      },

      invoice_options: {
        is_installment: t('Payment by installment')
      },

      customer: {
        first_name: t('First name'),
        last_name: t('Last name'),
        email: t('Email'),
        phone: t('Phone'),
        relation_to_deceased: t('Relation to the deceased'),
        social_security_number: t('Social security number'),
        address_1: t('Address'),
        postcode: t('Post code'),
        city: t('Location')
      },

      deceased: {
        first_name: t('First name'),
        last_name: t('Last name'),
        social_security_number: t('Social security number'),
        obituary_creator_url: t('Obituary creator URL')
      }
    };

    if (type === 'therapy_order') {
      data.offer = {
        payment_deadline: t('Payment deadline')
      };
    }

    if (type === 'lawyer_order' || type === 'all') {
      data.order.funeral_date = t('Funeral date');

      data.deceased = {
        ...data.deceased,
        ...{
          estate_agent_evaluation: t('Property evaluation'),
          estate_agent_sale: t('Property sale')
        }
      };

      data.suppliers = {
        fastighetsmaklare: t('Estate agent')
      };

      data.appointments = getAppointmentTypes();

      return data;
    }

    data.entombment = {
      entombment: t('Entombment'),
      place: t('Funeral place'),
      graveyard: t('Graveyard'),
      burial_rights_holder: t('Burial rights holder'),
      participation: t('Relatives present'),
      note: t('Note')
    };

    if (type === 'tombstone_order') {
      data.entombment.type = t('Type of ceremony');
      data.appointments = getAppointmentTypes();

      data.deceased = {
        ...data.deceased,
        ...{
          birth_year: t('Birth year'),
          death_year: t('Death year')
        }
      };

      return data;
    }

    data.deceased = {
      ...data.deceased,
      ...{
        address: t('Address'),
        postcode: t('Post code'),
        city: t('Location'),
        morgue: t('Morgue'),
        date: t('Date'),
        parish: t('Parish'),
        pick_up_date: t('Pick up date'),
        own_clothes: t('Own clothes'),
        estate_agent_evaluation: t('Property evaluation'),
        estate_agent_sale: t('Property sale'),
        note: t('Note')
      }
    };

    if (taskCountry && taskCountry.code !== 'se') {
      data.deceased = {
        ...data.deceased,
        ...{
          member_of_state_church: t('Member of state church')
        }
      };
    }

    if (taskCountry && taskCountry.code === 'dk') {
      data.deceased = {
        ...data.deceased,
        ...{
          last_will: t('Last will')
        }
      };

      data.entombment = {
        ...data.entombment,
        ...{
          urn_collection: t('Who will collect the urn')
        }
      };
    }

    data.funeral = {
      ceremony_place: t('Ceremony venue'),
      divine_economy: t('Divine economy'),
      type: t('Type'),
      graveyard: t('Graveyard'),
      grave_place: t('Grave'),
      canope: t('Canope'),
      officiant: t('Official'),
      soloist: t('Musician'),
      organist: t('Organist'),
      janitor: t('Janitor'),
      clothing: t('Clothing'),
      expected_number_of_guests: t('Expected number of guests'),
      num_representatives: t('Number of representatives'),
      note: t('Note')
    };

    data.memorial = {
      place: t('Memorial venue'),
      food: t('Catering'),
      rsvp: t('RSVP memorial'),
      attendees: t('Attendees'),
      attendees_no_name: t('Attendees without names'),
      num_attendees: t('Number of attendees')
    };

    if (taskCountry && taskCountry.code === 'se') {
      data.transport = {
        coffin_storage: t('Coffin storage'),
        invoice_to_swedish_church: t('Invoice to Swedish church'),
        num_coffin_carriers: t('Coffin bearers to invoice Swedish church')
      };
    }

    data.memorial_page = {
      memorial_page_url: t('Link to the memorial page'),
      email_sharelink: t('Email sharelink for the memorial page'),
      creator_link: t('Link to the memorial page creator')
    };

    data.products = {
      flowers: t('Flowers for florist'),
      coffin: t('Coffin'),
      urn: t('Urn'),
      flowers_list: t('Flowers list'),
      tombstone: t('Tombstone'),
      has_programme: t('Has programme'),
      has_host: t('Has ceremony host'),
      official_fee: t('Officials fee')
    };

    data.suppliers = {
      transportoer: t('Transport provider'),
      blomleverantoer: t('Florist'),
      krematorium: t('Crematorium'),
      barhus: t('Morgue'),
      representant: t('Representatives'),
      minnesfond: t('Memorial fund'),
      fastighetsmaklare: t('Estate agent')
    };

    data.appointments = getAppointmentTypes();

    return data;
  }

  return {
    getShortcodes
  };
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../components/Spinner';
import Profile from './User/Profile';
import useUser from 'ReduxHooks/useUser';

const EditUser = ({ id, match }) => {
  const { loadUser, user } = useUser();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchUser();
  }, []);

  async function fetchUser () {
    await loadUser(id);
    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <Spinner loading />
    );
  }

  return (user) ? (
    <Profile match={match} />
  ) : null;
};

EditUser.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  match: PropTypes.object.isRequired
};

export default EditUser;

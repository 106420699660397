import React from 'react';
import { useTranslation } from 'react-i18next';

import { Notice } from 'Layout';
import { Toggle, LoadingButton } from 'FormElements';
import { If } from 'Components/Conditionals';

// Hooks
import useMemorialPage from 'ReduxHooks/useMemorialPage';

function MemorialPagePaid () {
  const { t } = useTranslation();
  const { memorialPage, updatePaid, isUnlocking } = useMemorialPage();
  const memorialPageLocked = !!memorialPage.website_is_locked;
  const memorialPagePaid = !!memorialPage.website_is_paid;
  const status = memorialPageLocked ? 'error' : !memorialPagePaid ? 'warning' : 'notice';

  return (
    <Notice
      classlist='memorial-page-notice memorial-page-paid'
      heading={t('Paid version')}
      type={status}
    >
      <If condition={memorialPageLocked}>
        <p>{t('This memorial page has been unpublished.') + ' ' + t("The grace period has passed and the customer hasn't paid for the extended time.")}</p>
        <p>{t('If the page has been paid for click the button below to publish the page again.')}</p>
      </If>

      <If condition={memorialPageLocked}>
        <LoadingButton
          disabled={false}
          isLoading={isUnlocking}
          onClick={updatePaid}
        >
          {t('Customer has paid, republish page')}
        </LoadingButton>
      </If>

      <If condition={!memorialPageLocked}>
        <Toggle
          label={t('Customer has paid')}
          data-cy='memorial_page.website_is_paid'
          onChange={() => updatePaid()}
          defaultChecked={memorialPagePaid}
        />
      </If>
    </Notice>
  );
}

export default MemorialPagePaid;

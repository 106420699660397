import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Notice } from 'Layout';
import { Toggle } from 'FormElements';
import OrderSupplierDropdown from 'Components/OrderSupplierDropdown';
import { If } from 'Components/Conditionals';

// Hooks
import useMemorialPage from 'ReduxHooks/useMemorialPage';

function MemorialPageFundOptions ({ memorialFund }) {
  const { t } = useTranslation();
  const { memorialPage, updateNoFund, isUpdatingNoFund } = useMemorialPage();
  const [noFund, setNoFund] = useState(!!memorialPage.website_memorial_fund_disabled);
  const fundChosen = !!memorialFund;
  const fundSupportDonations = !!memorialFund && !!memorialFund.pennybridge_id;
  const status = noFund ? 'error' : (fundChosen && !fundSupportDonations) ? 'warning' : 'notice';

  useEffect(() => {
    setNoFund(!!memorialPage.website_memorial_fund_disabled);
  }, [memorialPage]);

  return (
    <Notice
      classlist={`${isUpdatingNoFund ? 'memorial-page-notice-loading ' : ''} memorial-page-notice memorial-page-donation-info`}
      heading={t('Donation support')}
      type={status}
    >
      <If condition={noFund}>
        <p>{t('Visitors on this memorial page will not be able to donate!')}</p>
      </If>

      <If condition={!noFund && !fundChosen}>
        <p>{t('Visitors on this memorial page will be able to donate. Choose a fund to use a specific fund for donations on the page.')}</p>
      </If>

      <If condition={!noFund && fundChosen && fundSupportDonations}>
        <p>{t('Visitors on this memorial page will be able to donate to the chosen fund.')}</p>
      </If>

      <If condition={!noFund && fundChosen && !fundSupportDonations}>
        <p>{t('Visitors on this memorial page will be not be able to donate. The chosen fund does not support donations on the memorial page.')}</p>
      </If>

      <Toggle
        label={t('Memorial page supports donations')}
        data-cy='memorial_page.website_memorial_fund_disabled'
        onChange={() => updateNoFund()}
        defaultChecked={!noFund}
      />

      <If condition={!noFund}>
        <div className='row'>
          <OrderSupplierDropdown
            label={t('Memorial fund')}
            type='minnesfond'
            className='col-6'
            noValueLabel={t('-- No memorial fund selected --')}
          />
        </div>
      </If>
    </Notice>
  );
}

MemorialPageFundOptions.propTypes = {
  memorialFund: PropTypes.object
};

export default MemorialPageFundOptions;

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getTabIndexes } from 'Library/TabHelperFunctions';

function useTabIndexes ({
  uri,
  type = 'App\\Order',
  tab = null,
  authorizationRules = {}
}) {
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(getInitialTabIndex());

  function updateTabs (index) {
    setTabIndex(index);
    history.replace(`${uri}/${getTabIndexes(type, authorizationRules)[index]}`);
  }

  function getInitialTabIndex () {
    if (!tab) {
      return 0;
    }

    const index = getTabIndexes(type, authorizationRules).findIndex(tabIndex => tabIndex === tab);
    return index > -1 ? index : 0;
  }

  return {
    tabIndex,
    updateTabs
  };
}

export default useTabIndexes;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notice } from 'Layout/index';
import { Link } from 'react-router-dom';
import Spinner from 'Components/Spinner';

const UnavailableOrderNotice = ({ isLoading, hasRendered }) => {
  const { t } = useTranslation();

  if (!hasRendered || isLoading) {
    return <Spinner />;
  }

  return (
    <Notice type='info' heading={'Whoops!'}>
      <p>{t('The order could not be found which, in most cases, is due to one of the following reasons')}:</p>
      <ul>
        <li>{t('The order/offer has been deleted or archived')}</li>
        <li>{t('The order has been assigned to another counsellor')}</li>
        <li>{t('The URL is incorrect or invalid')}</li>
        <li>{t('You have been logged out')}</li>
      </ul>
      <p>{t('Please try again by reloading the page, or use the menu to find what you\'re looking for.')}</p>
      <Link to='/'>{t('Go to the dashboard')}</Link>
    </Notice>
  );
}

export default UnavailableOrderNotice;

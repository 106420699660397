export function getTabIndexes (type = 'App\\Order', authorizationRules = {}) {
  switch (type) {
    case 'App\\TombstoneOrder':
      return [
        'kund',
        'avliden',
        'gravsatting',
        'produkter',
        'filer'
      ];

    case 'App\\TherapyOrder':
      return [
        'kund',
        'sessions',
        'records',
        'filer'
      ];

    case 'App\\CeremonyOrder':
      return [
        'kund',
        'produkter',
        'filer'
      ];
    case 'App\\LawyerOrder':
      return [
        'kund',
        'avliden',
        'produkter',
        'filer'
      ];

    case 'Profile':
      return getUserProfileTabs(authorizationRules);

    default:
      return [
        'kund',
        'avliden',
        'ceremoni',
        'minnessida',
        'gravsattning',
        'minnesstund',
        'transport',
        'blommor',
        'produkter',
        'filer'
      ];
  }
}

const getUserProfileTabs = (authorizationRules) => {
  const tabs = [
    'min-profil',
    'kompetenser',
    'schema',
    'profil',
    'admin',
    'notifikationer',
    'ekonomi',
    'omdomen'
  ];

  const { showNoticesTab, showEconomyTab, showAdminTab, showCompetenceTab } = authorizationRules;

  removeTabIfUnavailable(tabs, showNoticesTab, 'notifikationer');
  removeTabIfUnavailable(tabs, showEconomyTab, 'ekonomi');
  removeTabIfUnavailable(tabs, showAdminTab, 'admin');
  removeTabIfUnavailable(tabs, showCompetenceTab, 'kompetenser');

  return tabs;
};

const removeTabIfUnavailable = (tabs, booleanValue, tabName) => {
  if (!booleanValue) {
    const index = tabs.indexOf(tabName);
    if (index > -1) {
      tabs.splice(index, 1);
    }
  }
};

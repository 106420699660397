import { If } from 'Conditionals';
import React from 'react';
import moment from 'moment';
import { getOrderFuneralPlace } from 'Library/OrderHelperFuntions';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import useAppointments from 'Hooks/useAppointments';
import { AnimatePresence, motion } from 'framer-motion';
import { overlayMotionProps } from 'Library/motion';
import { useTranslation } from 'react-i18next';

function CeremonyInfoPreview () {
  const {
    order,
    order: {
      memorial_page: memorialPage
    }
  } = useSingleOrder();
  const { t } = useTranslation();
  const { getAppointmentOfType } = useAppointments();
  const ceremony = getAppointmentOfType('ceremony');
  const ceremonyDate = (ceremony) ? moment(ceremony.start_date) : moment();
  const funeralPlace = getOrderFuneralPlace(order);

  return (
    <AnimatePresence>
      <If condition={!memorialPage.website_hide_ceremony_information && ceremony}>
        <motion.section
          {...overlayMotionProps}
          className='obituary-single__info'
        >
          <div className='obituary-single__info-wrapper'>
            <div className='obituary-single__info-heading'>
              <svg className='obituary-single__info-illustration' viewBox='0 0 73 52'>
                <use href='#funeral-building' />
              </svg>
              <h2>{t('About the funeral')}</h2>
            </div>

            <div className='obituary-single__info-content'>
              <div className='obituary-single__info-ceremoni-time'>
                <div className='obituary-single__info-date'>
                  <span>{ceremonyDate.format('D')}</span>
                  <span>{ceremonyDate.locale('se').format('MMM')}</span>
                </div>
                <div className='obituary-single__info-day-time'>
                  <span>{ceremonyDate.locale('se').format('dddd')}</span>
                  <span>{t('at', { description: 'at 21:00, kl. 21:00 in Swedish' })} {ceremonyDate.format('HH:mm')}</span>
                </div>
              </div>

              <If condition={funeralPlace}>
                <p className='obituary-single__info-ceremony-place'>
                  {`${t('The ceremony will be held in')} ${funeralPlace}`}
                </p>
              </If>
            </div>
          </div>
        </motion.section>
      </If>
    </AnimatePresence>
  );
}

export default CeremonyInfoPreview;

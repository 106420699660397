import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Imgix from 'react-imgix';
import Skeleton from 'react-loading-skeleton';
import { USER_BG_IMG, USER_CARD_BG_IMG } from 'Library/MemorialPageHelpers';
import { blobToFile, lookForCroppedImageName } from 'Library/ImageProcessorHelpers';
import ImageUpload from '../ImageUpload';
import useMemorialPage from 'ReduxHooks/useMemorialPage';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';

const ImageListUploadButton = ({ isCardImageList = false }) => {
  const { t } = useTranslation();
  const { updateField, persist } = useSingleOrder();
  const { attachImage, detachImage, memorialPage, findImageOfType } = useMemorialPage();

  const [selected, setSelected] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const key = (isCardImageList) ? 'website_card_bg_image_id' : 'website_card_image_id';
  const wpImage = memorialPage[key];
  const type = isCardImageList ? USER_CARD_BG_IMG : USER_BG_IMG;
  const userImage = findImageOfType(type);

  useEffect(() => {
    if (wpImage) {
      setSelected(false);
    } else {
      setSelected(true);
    }
  }, [wpImage]);

  const select = (e) => {
    e.preventDefault();
    updateField(`memorial_page.${key}`, null);
  };

  const openModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setIsOpen(false);
  };

  const closeSave = async (e, croppedCanvas) => {
    e.preventDefault();

    setIsLoading(true);

    await updateField(`memorial_page.${key}`, null);
    await persist();

    const blobName = isCardImageList
      ? `memorial_page_user_card_bg_image_${memorialPage.id}`
      : `memorial_page_user_bg_image_cropped_${memorialPage.id}`;

    croppedCanvas.toBlob(async (blob) => {
      const file = blobToFile(blob, blobName);
      const formData = new FormData();

      formData.append('file', file);
      formData.append('type', type);

      await attachImage(formData);
      setIsLoading(false);
    }, 'image/jpeg', 1);

    setIsOpen(false);
  };

  const removeImage = async (e, image) => {
    e.preventDefault();

    setIsLoading(true);
    await detachImage(image.id);
    setIsLoading(false);
    setIsOpen(false);
  };

  const imgixParams = () => {
    if (!lookForCroppedImageName(userImage.src)) {
      return { crop: 'faces, edges' };
    }

    return { crop: 'center' };
  };

  const modalHeading = isCardImageList ? t('Upload your own background for the card') : t('Upload your own background');
  const modalDesc = isCardImageList
    ? t('For the best results – use a close-up with good quality and sharpness. Select an image that is neutral. The text will end up on the left on top of the image.')
    : t('For the best results – use a close-up with good quality and sharpness. Select an image that is neutral.');

  return (
    <div className='image-list-upload-button__wrapper image-list-item'>
      {userImage ? (
        <div
          className={classNames('image-list-item__labelish', { selected })}
        >
          <a href='#' onClick={select}>
            {isLoading ? (
              <span className='image-list-upload-button__skeleton'>
                <Skeleton width={200} height={100} />
              </span>
            ) : (
              <Imgix
                src={userImage.url}
                width={200}
                height={100}
                imgixParams={{
                  ...imgixParams(),
                  fit: 'crop',
                  w: 200,
                  h: 100
                }}
              />
            )}
          </a>

          <button
            className='btn btn-link'
            disabled={isLoading}
            onClick={openModal}
          >
            {t('Change image')}
          </button>
        </div>
      ) : (
        <button
          className='image-list-upload-button'
          onClick={openModal}
        >
          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
            <path fill='#437783' d='M1.532 15.621l4.984-5.538A.766.766 0 0 1 7.6 10.03l7.23 6.573 3.7-3.084a.766.766 0 0 1 .982 0l2.956 2.464V3.574a2.043 2.043 0 0 0-2.042-2.042H3.574a2.043 2.043 0 0 0-2.042 2.042v12.047zm0 2.29v2.515c0 1.127.914 2.042 2.042 2.042h16.852a2.043 2.043 0 0 0 2.042-2.042v-2.45l-3.447-2.873-3.722 3.102a.766.766 0 0 1-1.006-.021L7.14 11.68l-5.607 6.23zM24 17.612a.77.77 0 0 1 0 .01v2.804C24 22.4 22.4 24 20.426 24H3.574A3.575 3.575 0 0 1 0 20.426V17.62a.77.77 0 0 1 0-.006V3.574C0 1.6 1.6 0 3.574 0h16.852C22.4 0 24 1.6 24 3.574v14.038zM18.957 7.085a2.043 2.043 0 1 0-4.085 0 2.043 2.043 0 0 0 4.085 0zm1.532 0a3.575 3.575 0 1 1-7.149 0 3.575 3.575 0 0 1 7.15 0z' />
          </svg>
          <span>{t('Custom image')}</span>
        </button>
      )}

      <ImageUpload
        chosenImage={userImage}
        closeModal={closeModal}
        closeSave={closeSave}
        removeImage={removeImage}
        description={modalDesc}
        isLoading={isLoading}
        modalHeading={modalHeading}
        isOpen={isOpen}
        isBanner={!isCardImageList}
        isCard={isCardImageList}
      />
    </div>
  );
};

ImageListUploadButton.propTypes = {
  isCardImageList: PropTypes.bool
};

export default ImageListUploadButton;

import API from '../../stores/API';
import useSingleOrder from './useSingleOrder';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPreferedImages } from '../actions/memorialPageImages';
import { showNotice } from 'Library/notifications';
import { useTranslation } from 'react-i18next';

function useMemorialPage () {
  const dispatch = useDispatch();
  const { defaultImages } = useSelector(state => state.memorialPageImages);
  const { order, orderActionCallback, persist, updateField } = useSingleOrder();
  const { memorial_page: memorialPage } = order;
  const [isUnlocking, setIsUnlocking] = useState(false);
  const [isUpdatingNoFund, setIsUpdatingNoFund] = useState(false);
  const { t } = useTranslation();

  async function publishMemorialPage () {
    const response = await API.POST(`/memorial-page/publish/${memorialPage.id}`);
    return orderActionCallback(response);
  }

  async function toggleMemorialVisibility (visibility) {
    const response = await API.POST(`/memorial-page/toggle-visibility/${memorialPage.id}`, { visibility });
    return orderActionCallback(response);
  }

  async function attachImage (formData, json = false) {
    await persist(false, false);
    const response = await API.POST(`/memorial-page/attach-file/${memorialPage.id}`, formData, json);
    return orderActionCallback(response, false);
  }

  async function detachImage (imageId) {
    await persist(false, false);
    const response = await API.DELETE(`/memorial-page/detach-file/${memorialPage.id}/${imageId}`);
    return orderActionCallback(response, false);
  }

  async function unlockMemorialPage () {
    const response = await API.POST(`/memorial-page/toggle-locked/${memorialPage.id}`, { locked: false });
    return orderActionCallback(response);
  }

  async function updateNoFund () {
    setIsUpdatingNoFund(true);
    const noFund = memorialPage.no_fund;

    const response = await API.POST(`/memorial-page/update-no-fund/${memorialPage.id}`, { no_fund: !memorialPage.website_memorial_fund_disabled });
    await orderActionCallback(response);

    setIsUpdatingNoFund(false);

    // This looks wrong but it's not. The value is not updated in the store before the response is returned.
    const noticeText = !noFund ? t('The memorial page no longer supports donations.') : `🎉 ${t('The memorial page now supports donations.')}`;

    showNotice(
      noticeText,
      'success',
      7500
    );
  }

  async function getDonations (archives = false) {
    let uri = `/memorial-page/${order.id}/get-donations`;

    if (archives) {
      uri += '?with_comment=1';
    }

    return API.GET(uri);
  }

  async function generateArchive () {
    return API.POST(`/memorial-page/${order.id}/request-image-archive-via-admin`);
  }

  async function loadDefaultImages () {
    if (defaultImages.length) {
      return;
    }

    await dispatch(getPreferedImages());
  }

  async function updateVisibility (visibility) {
    visibility = (visibility) ? 'visible' : 'hidden';

    await toggleMemorialVisibility(visibility);
    const visible = (visibility === 'visible') ? 'är nu synlig i' : 'har gömts från';

    showNotice(
      `🎉 Minnessidan ${visible} arkivet. <strong>OBS!</strong> Det kan dröja en stund innan ändringarna slår igenom på lavendla.se`,
      'success',
      7500
    );
  }

  async function unlockPage () {
    setIsUnlocking(true);

    await unlockMemorialPage();

    setIsUnlocking(false);

    showNotice(
      `🎉 ${t('The memorial page is no longer unpublished and will now be visible on the website.')}`,
      'success',
      7500
    );
  }

  async function updatePaid () {
    const paid = memorialPage.website_is_paid;

    if (!paid && !!memorialPage.website_is_locked) {
      await unlockPage();
    } else {
      await updateField('memorial_page.website_is_paid', !memorialPage.website_is_paid);
    }
  }

  return {
    memorialPage,
    publishMemorialPage,
    getDonations,
    generateArchive,
    attachImage,
    detachImage,
    defaultImages,
    loadDefaultImages,
    updateVisibility,
    updatePaid,
    updateNoFund,
    isUnlocking,
    isUpdatingNoFund,
    findImageOfType: (type) => memorialPage.files.find(file => file.type === type)
  };
}

export default useMemorialPage;
